<template>
    <div class="app-body">
		<bread-crumb></bread-crumb>
        <el-card class=" main orderdetail">
            <div class="orderdetail-top a-flex-rfsc">
                <div class="a-flex-rfsc">
                    <span class="a-fs-16 a-fw-700">订单：{{ detail.orderId }}</span>
                    <span class="a-c-error">{{ detail.continueOrder ? '（续单）' : '' }}</span>
                    
                </div>
                <el-tag effect="plain" type="info" class="a-ml-16"> <span class="roundspan"
                        :class="{ 'grey': detail.status == 3 || detail.status == 5, 'red': detail.status == 4 }"></span>{{ detail.statusText
                        }}
                </el-tag>
                <div class="a-flex-rfsc" v-if="detail.anxinService ">
                    <el-image
                        style="width: 21px;height: 16px !important;margin: 0 5px;"
                        fit='contain'
                        src="https://hichar-file.oss-cn-shanghai.aliyuncs.com/app/wxapp/rest-charge-icon.png"></el-image>
                    <span style="color: #FE7B11;" class="a-fs-14 a-fw-500">安心充服务保障中</span>
                </div>
            </div>
            <div :class="(detail.status == 3 || detail.status == 4 || detail.status == 5) ? 'orderdetail-step-cancle' : 'orderdetail-step'">
                <el-steps :active="active" :finish-status="(detail.status == 3 || detail.status == 4 || detail.status == 5) ? 'error' : 'success'" align-center>
                    <el-step title="订单创建" :description="detail.createTimeText" icon="el-icon-my-yk_yuanquan_fill"></el-step>
                    <el-step title="开始充电" :description="detail.startTimeText" icon="el-icon-my-yk_yuanquan_fill"></el-step>
                    <el-step title="支付成功" :description="detail.payTimeText" icon="el-icon-my-yk_yuanquan_fill"></el-step>
                    <el-step title="充电结束" :description="detail.endTimeText" icon="el-icon-my-yk_yuanquan_fill"></el-step>
                </el-steps>
            </div>
            <div class="orderdetail-box">
                <div class="a-fs-16 a-fw-700">订单信息</div>
                <div class="orderdetail-box-orderinfo">
                    <div class="a-flex-rfsc title">
                        <span class="dot"></span>
                        <span class="a-fs-14 a-ml-08">用户信息</span>
                    </div>
                    <div class="content a-flex-rfsc a-flex-wrap a-w-100">
                        <div>
                            <span>用户名</span>
                            <le-jumpto-detail url="/userMGT/userMGT-detail" :dQuery="{ 'id': detail.userId }"><span>{{
                                    detail.userName
                            }}</span></le-jumpto-detail>

                        </div>
                        <div>
                            <span>手机号</span>
                            <span>{{ userInfo.dataPermission == 77 ? util.hidePhoneNumber(detail.mobile) : detail.mobile }}</span>
                        </div>
                    </div>
                    <div class="a-flex-rfsc title">
                        <span class="dot"></span>
                        <span class="a-fs-14 a-ml-08">设备信息</span>
                    </div>
                    <div class="content a-flex-rsbc a-flex-wrap a-w-100">
                        <div>
                            <span>设备编号</span>
                            <le-jumpto-detail url="/device/device-info" :dQuery="{ 'deviceCode': detail.deviceCode }">
                                <span>{{ detail.deviceCode
                                }}</span>
                            </le-jumpto-detail>

                        </div>
                        <div>
                            <span>充电插口</span>
                            <!-- <span>{{ detail.slot ? '右' : '左' }}</span> -->
                            <span>{{detail.slotNum <= 2 ? detail.slot==0?'左':'右':((detail.slot+1)+'号插座')}}</span>
                            
                        </div>
                        <div>
                            <span>设备地址</span>
                            <le-jumpto-detail url="/station/station-detail" :dQuery="{ 'stationId': detail.stationId }">
                                <span>{{ detail.address }}</span>
                            </le-jumpto-detail>

                        </div>
                    </div>
                    <div class="a-flex-rfsc title">
                        <span class="dot"></span>
                        <span class="a-fs-14 a-ml-08">充电信息</span>
                    </div>
                    <div class="content a-flex-rsbc a-flex-wrap a-w-100">
                        <div>
                            <span>下单时间</span>
                            <span>{{ detail.createTimeText }}</span>
                        </div>
                        <div>
                            <span>开始时间</span>
                            <span>{{ detail.startTimeText }}</span>
                        </div>
                        <div>
                            <span>结束时间</span>
                            <span>{{ detail.endTimeText }}</span>
                        </div>
                        <div v-if="chargePlanInfo.type == 6 || chargePlanInfo.type == 8">
                            <span>购买金额</span>
                            <span>￥{{ util.numFormat(detail.planAmount) }}</span>
                        </div>
                        <div v-else>
                            <span>购买时长</span>
                            <span>{{ detail.planMinutes }} 分钟</span>
                        </div>
                        <div>
                            <span>实际时长</span>
                            <span>{{ detail.realMinutes }} 分钟</span>
                        </div>
                        <div>
                            <span>电量</span>
                            <span>{{ detail.activeApparent || detail.energyApparent }}kwh</span>
                        </div>
                        <div>
                            <span>电费</span>
                            <span>￥{{ util.numFormat(detail.chargingFee) }}</span>
                        </div>
                        <div>
                            <span>占位服务费</span>
                            <span>￥{{ util.numFormat(detail.serviceFee) }}</span>
                        </div>
                        <div>
                            <span>充电服务费</span>
                            <span>￥{{ util.numFormat(detail.chargeServiceFee) }}</span>
                        </div>
                        <div>
                            <span>订单状态</span>
                            <div>
                                <span class="roundspan"
                                    :class="{ 'grey': detail.status == 3, 'red': detail.status == 4 }"></span>
                                <span>{{ detail.statusText }}</span>
                            </div>
                        </div>
                        <div>
                            <span>结束原因</span>
                            <span>{{ detail.endReason }}</span>
                        </div>
                        <div v-if="detail.orderType != 4 && detail.orderType != 5">
                            <span>计费标准</span>
                            <span>{{ detail.payRule || '-' }}</span>
                        </div>
                        <div>
                            <span>刷卡卡号</span>
                            <span>{{ detail.cardNo ? detail.cardNo : '-' }}</span>
                        </div>
                        <div>
                            <span>充电方式</span>
                            <span>{{ detail.isFullTime==1?'定时充':'充满自停' }}</span>
                        </div>
                    </div>
                    <div class="a-flex-rfsc title">
                        <span class="dot"></span>
                        <span class="a-fs-14 a-ml-08">支付信息</span>
                    </div>
                    <div class="content a-flex-rfsfs a-flex-wrap a-w-100">
                        <div>
                            <span>支付时间</span>
                            <span>{{ detail.payTimeText }}</span>
                        </div>
                        <div>
                            <span>支付方式</span>
                            <span>{{ detail.payTypeText }}</span>
                        </div>
                        <div>
                            <span>支付类型</span>
                            <span>{{ detail.payWay == 1 ? '现付' : '后付' }}</span>
                        </div>
                        <div>
                            <span>订单来源</span>
                            <span>{{ detail.orderSourceTypeText }}</span>
                        </div>
                        <div>
                            <span>产生费用</span>
                            <span>&yen; {{ util.numFormat(detail.amount) }}</span>
                        </div>
                        <div>
                            <span>订单金额</span>
                            <span>&yen; {{ util.numFormat(detail.payWay == 1 ? detail.planAmount : detail.amount) }}</span>
                        </div>
                        <div>
                            <span>实际收益</span>
                            <span>&yen; {{ util.numFormat(realAmount) }}</span>
                        </div>
                        <!-- <div>
                            <span>退款金额</span>
                            <span>&yen; {{ util.numFormat(detail.refundAmount) }}</span>
                        </div> -->
                        <div v-if="detail.giveReason">
                            <span style="min-width:56px">赠予原因</span>
                            <span>{{ detail.giveReason?detail.giveReason:'-' }}</span>
                        </div>
                    </div>
                    <div class="a-flex-rfsc title">
                        <span class="dot"></span>
                        <span class="a-fs-14 a-ml-08">支付信息明细</span>
                    </div>
                    <el-table :border='true' :data="payInfo" style="width: 800px;margin-left:14px;margin-top: 14px">
                        <el-table-column label=""  prop='desc'></el-table-column>
                        <el-table-column label="支付金额" >
                            <template slot-scope="{ row }">
                                <span v-if="row.discountType == 4">通用：￥{{ util.numFormat(row.details.payGlobalPoints || 0) }}，站点：￥{{ util.numFormat(row.details.paySatationPoints || 0) }}</span>
                                <span v-else>￥{{ util.numFormat(row.amount) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="退款金额">
                            <template slot-scope="{ row }">
                                <span v-if="row.discountType == 4">通用：￥{{ util.numFormat(row.details.refundGlobalPoints || 0) }}，站点：￥{{ util.numFormat(row.details.refundSatationPoints || 0) }}</span>
                                <span v-else>￥{{ util.numFormat(row.refoundAmount) }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="a-flex-rfsc title" v-if="restCharge">
                        <span class="dot"></span>
                        <span class="a-fs-14 a-ml-08">安心充支付信息</span>
                    </div>
                    <div class="content a-flex-rfsfs a-flex-wrap a-w-100" v-if="restCharge">
                        <div>
                            <span>支付时间</span>
                            <span>{{ detail.payTimeText }}</span>
                        </div>
                        <div>
                            <span>支付方式</span>
                            <span>{{ detail.payTypeText }}</span>
                        </div>
                        <div>
                            <span>支付类型</span>
                            <span>{{ detail.payWay == 1 ? '现付' : '后付' }}</span>
                        </div>
                        <div>
                            <span>支付状态</span>
                            <span>{{ restCharge.payStatus | initDic(packagePayStatusDic) }}</span>
                        </div>
                        <div>
                            <span>订单状态</span>
                            <div>
                                <span class="roundspan"
                                    :class="{ 'grey': restCharge.orderStatus == 3, 'red': restCharge.orderStatus == 4 }"></span>
                                <span>{{ restCharge.orderStatus | initDic(packageOrderStatusDic) }}</span>
                            </div>
                        </div>
                        <div>
                            <span>订单来源</span>
                            <span>{{ detail.orderSourceTypeText }}</span>
                        </div>
                        <div>
                            <span>支付金额</span>
                            <span>&yen; {{ util.numFormat(restCharge.payAmount) }}</span>
                        </div>
                        <div>
                            <span>退款金额</span>
                            <span>&yen; {{ util.numFormat(restCharge.refundAmount) }}</span>
                        </div>
                        <div>
                            <span>实际费用</span>
                            <span>&yen; {{ util.numFormat(restCharge.payAmount - restCharge.refundAmount) }}</span>
                        </div>
                    </div>
                    <div class="a-flex-rfsc title" v-if="restCharge">
                        <span class="dot"></span>
                        <span class="a-fs-14 a-ml-08">安心充支付信息明细</span>
                    </div>
                    <el-table :border='true' :data="restChargePayInfo" style="width: 800px;margin-left:14px;margin-top: 14px" v-if="restCharge">
                        <el-table-column label=""  prop='desc'></el-table-column>
                        <el-table-column label="支付金额" >
                            <template slot-scope="{ row }">
                                <span v-if="row.discountType == 4">通用：￥{{ util.numFormat(row.details.payGlobalPoints || 0) }}，站点：￥{{ util.numFormat(row.details.paySatationPoints || 0) }}</span>
                                <span v-else>￥{{ util.numFormat(row.amount) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="退款金额">
                            <template slot-scope="{ row }">
                                <span v-if="row.discountType == 4">通用：￥{{ util.numFormat(row.details.refundGlobalPoints || 0) }}，站点：￥{{ util.numFormat(row.details.refundSatationPoints || 0) }}</span>
                                <span v-else>￥{{ util.numFormat(row.refoundAmount) }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="a-flex-rfsc title" v-if="couponOrder">
                        <span class="dot"></span>
                        <span class="a-fs-14 a-ml-08">优惠券包订单信息</span>
                    </div>
                    <div class="content a-flex-rfsfs a-flex-wrap a-w-100" v-if="couponOrder_goodsInfo">
                        <div>
                            <span>订单编号</span>
                            <span class="a-c-blue font-point" style="color: #007aff" @click="handleToCouponOrder">{{ couponOrder.orderSn || '-' }}</span>
                        </div>
                        <div>
                            <span>关联活动</span>
                            <span>{{ couponOrder_goodsInfo.activity.activityTitle }}</span>
                        </div>
                        <div>
                            <span>关联优惠券</span>
                            <span>{{ couponOrder_goodsInfo.goodsDetails.name }}</span>
                        </div>
                        <div>
                            <span>关联优惠券ID</span>
                            <span>{{ couponOrder_goodsInfo.goodsDetails.id }}</span>
                        </div>
                        <div>
                            <span>订单金额</span>
                            <span>￥{{ util.numFormat(couponOrder.orderPrice) }}</span>
                        </div>
                        <div>
                            <span>实际支付</span>
                            <span>￥{{ util.numFormat(couponOrder.payAmount - couponOrder.refundAmount) }}</span>
                        </div>
                        <div>
                            <span>支付状态</span>
                            <span>{{ couponOrder.payStatus | initDic(packagePayStatusDic) }}</span>
                        </div>
                    </div>
                    <div class="a-flex-rfsc title" v-if="couponOrder">
                        <span class="dot"></span>
                        <span class="a-fs-14 a-ml-08">优惠券包支付信息</span>
                    </div>
                    <el-table :border='true' :data="couponPayInfo" style="width: 800px;margin-left:14px;margin-top: 14px" v-if="couponOrder">
                        <el-table-column label=""  prop='desc'></el-table-column>
                        <el-table-column label="支付金额" >
                            <template slot-scope="{ row }">
                                <span v-if="row.discountType == 4">通用：￥{{ util.numFormat(row.details.payGlobalPoints || 0) }}，站点：￥{{ util.numFormat(row.details.paySatationPoints || 0) }}</span>
                                <span v-else>￥{{ util.numFormat(row.amount) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="退款金额">
                            <template slot-scope="{ row }">
                                <span v-if="row.discountType == 4">通用：￥{{ util.numFormat(row.details.refundGlobalPoints || 0) }}，站点：￥{{ util.numFormat(row.details.refundSatationPoints || 0) }}</span>
                                <span v-else>￥{{ util.numFormat(row.refoundAmount) }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <charge-plan :chargePlan='chargePlanInfo'></charge-plan>
                </div>
            </div>
            <div class="orderdetail-box">
                <div class="a-fs-16 a-fw-700">功率记录</div>
                <le-echarts-line :echartsObj="powerCharts" ></le-echarts-line>
                <div class="table">
                    <div class="table-header a-flex-rfsfs">
                        <span>时间</span>
                        <span>电流</span>
                        <span>有功功率</span>
                        <span>视在功率</span>
                        <span>有功电量</span>
                        <span>视在电量</span>
                    </div>
                    <div class="a-flex-rfsfs" v-for="(item, index) in powerList" :key="index">
                        <span>{{ item.powerTimeText }}</span>
                        <span>{{ item.rmsCurrent }}A</span>
                        <span>{{ item.activePower }}W</span>
                        <span>{{ item.apparentPower }}W</span>
                        <span>{{ item.activeEnergy }}kwh</span>
                        <span>{{ item.apparentEnergy }}kwh</span>
                    </div>
                </div>
            </div>
        </el-card>
        <le-station-list :tabledata='[]'></le-station-list>
    </div>
</template>
<script>
import {
    mapState,
    mapActions
} from 'vuex';
import util from '../../utils/util'
import chargePlan from './child/chargePlan.vue';
export default {
  components: { chargePlan },
    data() {
        return {
            deviceType: '',
            util: util,
            active: 0,
            reverse: true,
            detail: {},
            payInfo: [],//支付金额
            chargePlanInfo: '',
            restCharge: '',//安心充订单信息
            restChargePayInfo: [],//安心充支付信息明细
            couponOrder: '',//优惠券订单信息
            couponOrder_goodsInfo: '',//优惠券订单商品信息
            couponPayInfo: [],//优惠券支付信息
            packagePayStatusDic: [],//套餐支付状态字典
            packageOrderStatusDic: [],
            powerList: null,
            powerCharts:{
                id: 'xxecharts',
                xAxisData: [],
                seriesData0: [],
                seriesData1: [],
                seriesData2: [],
                seriesData3: [],
                seriesData4: [],
                seriesName0: '电流',
                seriesName1: '有功功率',
                seriesName2: '视在功率',
                seriesName3: '有功电量',
                seriesName4: '视在电量',
                seriesUnit0: 'A',
                seriesUnit1: 'W',
                seriesUnit2: 'W',
                seriesUnit3: 'kwh',
                seriesUnit4: 'kwh',
                yAxisIndex0: 1,
                yAxisIndex3: 2,
                yAxisIndex4: 2,
            },
        }
    },
    mounted() {
        this.getOrderInfoById();
        this.$getDic('payStatus').then(res=>{ this.packagePayStatusDic = res; })
        this.$getDic('orderStatus').then(res=>{ this.packageOrderStatusDic = res; })
    },
    computed: {
        realAmount () {
            // let refoundAmountAll = 0 // 所有优惠的退款
            // let discountPayAmountAll = 0
            // this.detail.orderDiscountInfoList.map(item=>{
            //     refoundAmountAll += item.refundPayAmount
            //     discountPayAmountAll += item.discountPayAmount
            // })
            return this.detail.payAmount - this.detail.refundAmount
        },
        ...mapState({
            userInfo: state => state.user.user,
        })
    },
    methods: {
        //获取订单详情
        getOrderInfoById() {
            this.$Axios._get({
                url: this.$Config.apiUrl.getOrderInfoById,
                params: {
                    orderId: this.$route.query.orderId
                },
            }).then((res) => {
                if (res.result.code == 0) {
                    this.detail = res.result.data
                    this.deviceType = res.result.data.deviceType
                    if (this.detail.createTimeText) {
                        this.active = 0
                    }
                    if (this.detail.startTimeText) {
                        this.active = 1
                    }
                    if (this.detail.payTimeText) {
                        this.active = 2
                    }
                    if (this.detail.endTimeText) {
                        this.active = 3
                    }
                    
                    // 优惠信息
                    let refoundAmountAll = 0
                    let discountArr = this.detail.orderDiscountInfoList.map(item=>{
                        refoundAmountAll += item.refundPayAmount
                        return {
                            ...item,
                            amount: item.discountPayAmount,
                            refoundAmount: item.refundPayAmount,
                            desc: this.$Config.discountTypeDic[item.discountType],
                            details: item.details ? JSON.parse(item.details) : ''
                        }
                    })
                    refoundAmountAll += this.detail.refundAmount
                    // this.payInfo[0] = {
                    //     amount: this.detail.payWay == 1 ? this.detail.planAmount : this.detail.amount,
                    //     refoundAmount: refoundAmountAll,
                    //     desc: '订单总金额'
                    // }
                    this.payInfo[1] = {
                        amount: this.detail.payAmount,
                        refoundAmount: this.detail.refundAmount,
                        desc: '现金'
                    }
                    this.payInfo = this.payInfo.concat(discountArr)

                    if(this.detail.chargingPlanDetails){
                        try {
                            this.chargePlanInfo = JSON.parse(this.detail.chargingPlanDetails)
                        } catch (error) {
                            
                        }
                    }

                    // 安心充订单信息
                    this.restCharge = res.data.superOrderItem.find(re=>{
                        return re.goodsType == 2
                    })
                    if(this.restCharge) {
                        this.restChargePayInfo = this.restCharge.orderDiscountInfoList ? this.restCharge.orderDiscountInfoList : []
                        this.restChargePayInfo = this.restChargePayInfo.map(item=>{
                            return {
                                amount: item.discountPayAmount,
                                refoundAmount: item.refundPayAmount,
                                desc: this.$Config.discountTypeDic[item.discountType]
                            }
                        })
                        this.restChargePayInfo.unshift({
                            amount: this.restCharge.payAmount,
                            refoundAmount: this.restCharge.refundAmount,
                            desc: '现金'
                        })
                    }
                    

                    // 优惠券订单信息
                    this.couponOrder = res.data.superOrderItem.find(re=>{
                        return re.goodsType == 4
                    })
                    if(this.couponOrder) {
                        this.couponPayInfo = this.couponOrder.orderDiscountInfoList ? this.couponOrder.orderDiscountInfoList : []
                        this.couponPayInfo = this.couponPayInfo.map(item=>{
                            return {
                                amount: item.discountPayAmount,
                                refoundAmount: item.refundPayAmount,
                                desc: this.$Config.discountTypeDic[item.discountType]
                            }
                        })
                        this.couponPayInfo.unshift({
                            amount: this.couponOrder.payAmount,
                            refoundAmount: this.couponOrder.refundAmount,
                            desc: '现金'
                        })

                        try {
                            this.couponOrder_goodsInfo = this.couponOrder.goodsInfo ? JSON.parse(this.couponOrder.goodsInfo) : ''
                            this.couponOrder_goodsInfo = {
                                ...this.couponOrder_goodsInfo,
                                goodsDetails: this.couponOrder_goodsInfo.goodsDetails?JSON.parse(this.couponOrder_goodsInfo.goodsDetails):""
                            }
                        } catch (error) {
                            
                        }
                    }
                    
                    
                 }
                this.getOrderPower();
            }).catch(err=>{
                this.getOrderPower();
            })
        },
        handleToCouponOrder () {
            this.$router.push({
                path:'/order/coupon-order-info',
                query: {
                    orderId: this.couponOrder.id,
                    orderSn: this.couponOrder.orderSn
                }
            })
        },
        //获取功率记录
        getOrderPower() {
            this.$Axios._get({
                url: this.$Config.apiUrl.getOrderPower,
                params: {
                    orderId: this.$route.query.orderId
                },
            }).then((res) => {
                if (res.result.code == 0) {
                    this.powerList = res.result.data.map(item=>{
                        // let activePower = 0
						// if(this.deviceType == 1 || this.deviceType == 2){
						// 	activePower = Number((item.apparentPower * 0.65).toFixed(3))
						// }else{
						// 	activePower = item.activePower
						// }
                        return {
                            ...item,
							// activePower: activePower
                        }
                    })
                    this.powerCharts.xAxisData = []
                    this.powerCharts.seriesData0 = []
                    this.powerCharts.seriesData1 = []
                    this.powerCharts.seriesData2 = []
                    this.powerCharts.seriesData3 = []
                    this.powerCharts.seriesData4 = []

                    this.powerList.map((item,index)=>{
                        this.powerCharts.xAxisData.push(item.powerTimeText)
                        this.powerCharts.seriesData0.push(item.rmsCurrent)
                        this.powerCharts.seriesData1.push(item.activePower)
                        this.powerCharts.seriesData2.push(item.apparentPower)
                        this.powerCharts.seriesData3.push(item.activeEnergy)
                        this.powerCharts.seriesData4.push(item.apparentEnergy)
                    })
                }
            });
        },
        //退款
        refund() {
            this.$confirm('确定进行订单退款?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {

                this.$Axios._get({
                    url: this.$Config.apiUrl.refundOrder,
                    params: {
                        orderId: parseInt(this.$route.query.orderId)
                    },
                }).then((res) => {
                    if (res.result.code == 0) {
                        this.$message({
                            type: 'success',
                            message: '订单开始退款!'
                        })
                    } else {
                        this.$message({
                            type: 'error',
                            message: '退款失败：' + res.result.msg
                        })
                    }
                }).catch((res) => {
                    this.$message({
                        type: 'error',
                        message: '退款失败：' + res.result.msg
                    })
                })




            }).catch(() => {

            });
        }
    }
}
</script>
<style lang="scss" scoped>
.main {
    height: 100%;
    overflow: auto;
}

.roundspan {
    height: 8px;
    width: 8px;
    background: #007AFF;
    border-radius: 100%;
    display: inline-block;
    margin-right: 6px;

    &.grey {
        background: #C0C4CC;
    }

    &.red {
        background: #FF3B30;
    }
}

.orderdetail {

    padding-bottom: 100px;

    &-top {
        border-bottom: 1px solid #EBF0F5;
        padding: 24px 24px 16px;
    }

    &-step {
        padding: 35px 0 0;
    }

    &-box {
        font-size: 14px;
        line-height: 22px;
        padding: 56px 24px 0;

        &-orderinfo {
            .title {
                padding-top: 16px;

                span {
                    font-weight: bold;

                    &.dot {
                        width: 6px;
                        height: 6px;
                        background: #797979;

                    }
                }
            }

            .content {
                padding-left: 14px;
                padding-bottom: 8px;

                >div {
                    width: 33.3%;
                    padding-top: 16px;
                    display: flex;
                    flex-flow: row;
                    justify-content: flex-start;
                    align-content: flex-start;



                    >span {
                        &:nth-of-type(1) {
                            color: #666666;
                            margin-right: 16px;
                            // font-family: PingFangSC-Regular, PingFang SC;
                        }

                        &:nth-of-type(2) {
                            color: #333;

                            font-family: PingFangSC-Regular, PingFang SC;

                        }
                    }
                }
            }

        }

        .table {
            padding-top: 16px;

            >div {
                font-size: 14px;
                line-height: 22px;
                padding: 14px 0;
                padding-left: 8px;

                >span {
                    width: 25%;
                    color: #303133;
                }

                border-bottom:1px solid #EBF0F5;
            }

            &-header {
                background: #F5F7FA;

                border: none;

                >span {

                    color: #606366;
                    font-weight: bold;
                }

            }
        }
    }
}

/deep/ .el-card__body {
    padding: 0;
}

.order {
    height: 8px;
    width: 8px;
    border-radius: 100%;
    background: #007AFF;
    display: inline-block;
}

.el-icon-my-yk_yuanquan_fill {
    font-size: 20px;
}

/deep/ .is-process .el-icon-my-yk_yuanquan_fill {
    color: #007AFF;
}

/deep/ .is-success .el-step__icon-inner {
    background: #007AFF;
    color: #fff;
    padding: 2px;
    border-radius: 100%;
}

/deep/ .el-step__head.is-success {
    color: #007AFF;
    border-color: #007AFF;

    .el-step__line {
        background-color: #007AFF;
    }
}

/deep/ .el-step__head.is-process {
    color: #007AFF;
    border-color: #007AFF;
}

/deep/ .el-step__title {
    color: #303133;
    font-size: 14px;
}

/deep/ .el-step__description {

    color: #909499
}

.orderdetail-step-cancle {
    padding: 35px 0 0;

    /deep/ .is-process .el-icon-my-yk_yuanquan_fill {
        color: #C0C4CC;
    }

    /deep/ .is-success .el-step__icon-inner {
        background: #C0C4CC;
        color: #fff;
        padding: 2px;
        border-radius: 100%;
    }

    /deep/ .el-step__head.is-success {
        color: #C0C4CC;
        border-color: #C0C4CC;

        .el-step__line {
            background-color: #C0C4CC;
        }
    }


    /deep/ .is-error .el-step__icon-inner {
        background: #C0C4CC;
        color: #fff;
        padding: 2px;
        border-radius: 100%;
    }

    /deep/ .el-step__head.is-error {
        color: #C0C4CC;
        border-color: #C0C4CC;

        .el-step__line {
            background-color: #C0C4CC;
        }
    }

    /deep/ .el-step__head.is-process {
        color: #C0C4CC;
        border-color: #C0C4CC;
    }
}

.footerBox {
    left: 255px;
    right: 43px;
    position: fixed;
    bottom: 0;
    background: #fff;
    padding-bottom: 24px;
}
</style>